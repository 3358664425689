import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    showMenu: false,
    load: false,
    auth: false,
    adminInfo: {},
    apiURL: 'https://appoffice.kz/ecp_get/api/index.php'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async postData(context, data) {
      const params = new URLSearchParams()
      for (let key in data) {
          params.append(key, data[key])
      }
      return axios.post(this.state.apiURL, params)
      .then(response => {
          if(response.data.succ){
              return {
                  succ: true,
                  data: response.data.data
              }
          }else{
              return {
                  succ: false,
                  mess: response.data.mess
              }
          }
      }) 
      .catch(error => {
          return {
              succ: false,
              mess: 'Не удалось выполнить запрос к серверу... ('+error+')'
          }
      })
  }
  },
  modules: {
  }
})
