<template>
  <dataBase :settings="settings"></dataBase>
</template>

<script>
import dataBase from '@/components/data_base/Database_reader.vue'

export default {
  name: "SpravkiPage",
  created() {},
  components:{
    dataBase
  },
  data() {
    return {
      settings: { 
        tableName: 'spravki',
        deleted: true,
        created: true,
        limit: 20,
        defoultSort: {
          column: 'status',
          type: 'DESC'
        },
        pagination: true,
        page: 1,
        buttons: [
          {
            class: 'btn-outline-success',
            type: 'route',
            link: '/company_info/:id',
            title: 'Настройки'
          }
        ],
        columns: [
          {
            columnName: 'icon',
            showName: 'иконка',
            search: false,
            read: true,
            type: 'icon',
            defVal: "'https://appoffice.kz/ecp_get/img/shield.png'",
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'id',
            showName: 'ID',
            search: true,
            read: false,
            type: 'text',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'name',
            showName: 'Название',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'status',
            showName: 'Статус',
            search: true,
            read: true,
            type: 'select',
            typeSearch: 'LIKE %%',
            defVal: '0',
            searchVal: '1',
            values:[
              {
                name: 'Активная',
                val: 1,
              },
              {
                name: 'Отключена',
                val: 0,
              }
            ]
          },
          {
            columnName: 'timeoute',
            showName: 'Время получения',
            search: true,
            read: true,
            type: 'number',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'id_egov',
            showName: 'ID Страницы получения',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'defoult_price',
            showName: 'Стоимость ум.',
            search: true,
            read: true,
            type: 'number',
            defVal: '0',
            typeSearch: '=',
            searchVal: '',
          },
          
        ]
      }
    };
  },
  props: {},
  computed:{
    
  },  
  methods: {},
};
</script>

<style lang="scss" scoped></style>