import { createRouter, createWebHistory  } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Companies from '@/views/Companies.vue'
import Logs from '@/views/Logs.vue';
import Settings from '@/views/Settings.vue'
import Stat from '@/views/Stat.vue'
import Orders from '@/views/Orders.vue'
import DosieInfo from '@/views/DosieInfoPage.vue'
import EcpInfo from '@/views/EcpInfoPage.vue'
import CompanyInfo from '@/views/CompanyInfo.vue'
import ProxyPage from '@/views/company/ProxySettingsPage.vue'
import SpravkiSettings from '@/views/company/SpravkiPage.vue'
import ServerSettings from '@/views/company/ServerSettings.vue'
import LangSettings from '@/views/company/LangSettings.vue'
import CreditCards from '@/views/company/Credit_cards.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies
  },
  {
    path: '/logs',
    name: 'logs',
    component: Logs
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
  },
  {
    path: '/stat',
    name: 'stat',
    component: Stat
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/dosieInfo/:id',
    name: 'dosieInfo',
    component: DosieInfo
  },
  {
    path: '/ecpInfoPage/:id',
    name: 'ecpInfo',
    component: EcpInfo
  },
  {
    path: '/company_info/:id',
    name: 'companyInfo',
    component: CompanyInfo
  },
  {
    path: '/proxy',
    name: 'proxySettings',
    component: ProxyPage
  },
  {
    path: '/spravki',
    name: 'spravkiSettings',
    component: SpravkiSettings
  },
  {
    path: '/server',
    name: 'serverSettings',
    component: ServerSettings
  },
  {
    path: '/langs',
    name: 'langsSettings',
    component: LangSettings
  },
  {
    path: '/credit_cards',
    name: 'CreditCards',
    component: CreditCards
  }
]

const router = createRouter({
  history: createWebHistory (),
  routes
})

export default router
