<template>
   <dataBase :settings="settings"></dataBase>
</template>
 
<script>
import dataBase from '@/components/data_base/Database_reader.vue'

export default {
  name: "CompaniesVue",
  created() {},
  components:{
    dataBase
  },
  data() {
    return {
      settings: { 
        tableName: 'companies',
        deleted: true,
        created: true,
        limit: 20,
        defoultSort: {
          column: 'balance',
          type: 'DESC'
        },
        pagination: true,
        page: 1,
        buttons: [
          {
            class: 'btn-outline-success',
            type: 'route',
            link: '/company_info/:id',
            title: 'Подробнее'
          }
        ],
        columns: [
          {
            columnName: 'id',
            showName: 'ID',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'title',
            showName: 'Название',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'date_create',
            showName: 'Дата создания',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'status',
            showName: 'Статус',
            search: true,
            read: true,
            type: 'selelecte',
            typeSearch: 'LIKE %%',
            defVal: 0,
            searchVal: '',
            values:[
              {
                name: 'Активная',
                val: 1,
              },
              {
                name: 'На модерации',
                val: 0,
              },
              {
                name: 'Заблокирована',
                val: 500,
              }
            ]
          },
          {
            columnName: 'text_status',
            showName: 'Сообщение',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'persones_count',
            showName: 'Сотрудники',
            search: false,
            read: false,
            type: 'number',
            defVal: 0,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'balance',
            showName: 'Баланс',
            search: true,
            read: true,
            type: 'number',
            defVal: 0,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'phone',
            showName: 'Телефон',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'email',
            showName: 'E-mail',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          }
        ]
      }
    };
  },
  props: {},
  computed:{
    
  },  
  methods: {},
};
</script>

<style lang="css" scoped>

</style>