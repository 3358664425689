<template>
  <div v-if="loadingTable" class="container-fluid  pt-4 px-4">
    <div v-if="!errLoad" class="bg-secondary text-center rounded p-4">
      <h3 class="text-start">{{ settings.title }}</h3>
      <hr>
      <div style="width: 100%; text-align: left;" class="mb-3">
          <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
            <button v-if="settings.deleted" @click="deleted()" type="button" :class="selectAll ? 'btn-primary' : 'btn-outline-primary'" class="btn">Удалиить выбранные</button>
            <button :disabled="create" v-if="settings.created" @click="createFunc()" type="button" class="btn btn-success">Добавить запись <span v-if="create" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
          </div>
      </div>
      <div class="table-responsive">
        <table class="table text-center align-middle table-bordered table-hover mb-0">
          <thead>
            <tr class="text-white">
              <th>
                <input class="form-check-input" type="checkbox" value="" @change="selectAllFunc()" v-model="selectAll">
              </th>
              <th v-for="column in settings.columns" :key="column.id" scope="col text-start">
                <input v-if="column.type == 'text' || column.type == 'number'" type="search" :disabled="!column.search" v-model="column.searchVal" @keyup="loadTable()" class="form-control form-control-sm" style="font-weight: bold;" :placeholder="column.showName">
                <select @change="loadTable()" v-if="column.type == 'select'" v-model="column.searchVal" class="form-control form-control-sm form-select form-select-sm">
                  <option v-for="select in column.values" :key="select" :value="select.val">{{ select.name }}</option>
                </select>
                <span v-if="column.type == 'icon'" style="font-weight: bold; padding-top: 10px;">{{ column.showName }}</span>
              </th>
              <th v-for="btn in settings.buttons" :key="btn.title" scope="col"></th>
              <th style="width: 40px;"></th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="data, count in resp.data.data" :key="data">
                <td>
                  <input class="form-check-input" v-model="resp.data.data[count].checked" type="checkbox" value="" id="flexCheckDefault">
                </td>
                <td v-for="cl, index in resp.data.columns.columns" :key="cl" :class="index == 0 ? 'text-start' : 'text-center'">

                  <div v-if="cl.type == 'text' || cl.type == 'number'">
                    <input v-if="cl.maskShow" v-mask="cl.mask" :placeholder="cl.showName" @keyup="updateVal(cl.columnName, data.id, count)" :disabled="!cl.read" type="text" v-model="resp.data.data[count][cl.columnName]" class="form-control form-control-sm">
                    <input v-else :placeholder="cl.showName" @keyup="updateVal(cl.columnName, data.id, count)" :disabled="!cl.read" type="text" v-model="resp.data.data[count][cl.columnName]" class="form-control form-control-sm">
                  </div>
                  

                  <select @change="updateVal(cl.columnName, data.id, count)" v-if="cl.type == 'select'" v-model="resp.data.data[count][cl.columnName]" class="form-control form-control-sm form-select form-select-sm">
                    <option v-for="select in cl.values" :key="select" :value="select.val">{{ select.name }}</option>
                  </select>

                  <div style="width: 100%; text-align: center;" v-if="cl.type == 'icon'">
                    <input type="file" class="changeInputIcon" ref="fileInput" @change="handleFileChange($event,count, cl)">
                    <img :src="resp.data.data[count][cl.columnName]" class="iconImage">
                  </div>
                </td>
                <td v-for="btn in settings.buttons" :key="btn.title" scope="col">
                  <button @click="btnEven(btn, data)" class="btn w-100 btn-sm" :class="btn.class">{{ btn.title }}</button>
                </td>
                <td><span v-if="data.updated" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></td>
              </tr>
          </tbody>
        </table>

        

        <div v-if="this.resp.data.countRows > 1" class="paginationBlock mt-5">
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" v-if="page > 1">
                <a class="page-link" href="#" @click.prevent="setCurrentPage(1)">В начало</a>
              </li>
              <li class="page-item" v-if="page > 1">
                <a class="page-link" href="#" @click.prevent="setCurrentPage(page - 1)">Назад</a>
              </li>
              <li v-for="pageNumber in displayedPages" :key="pageNumber" class="page-item" :class="{ active: page === pageNumber }">
                <a class="page-link" href="#" @click.prevent="setCurrentPage(pageNumber)">{{ pageNumber }}</a>
              </li>
              <li class="page-item" v-if="page < this.resp.data.countRows">
                <a class="page-link" href="#" @click.prevent="setCurrentPage(page + 1)">Следующая</a>
              </li>
              <li class="page-item" v-if="page < this.resp.data.countRows">
                <a class="page-link" href="#" @click.prevent="setCurrentPage(this.resp.data.countRows)">Последняя</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div v-else class="bg-secondary text-center rounded p-4">
      <div style="width: 100%; text-align: center; padding-top: 100px; padding-bottom: 100px;">
          <svg width="180" height="180" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>error-1</title><path d="M12 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-6 6a1 1 0 1 1-1.414-1.414L10.586 12 8.293 9.707a1 1 0 0 1 1.414-1.414L12 10.586zm-8 4.64l1.77 2.136a1 1 0 0 1-1.54 1.276l-2-2.414a1 1 0 0 1-.23-.638V8.414a1 1 0 0 1 .293-.707l5.414-5.414A1 1 0 0 1 8.414 2h7.646a1 1 0 0 1 .737.324l4.94 5.39a1 1 0 0 1 .263.675v7.222a1 1 0 0 1-.263.676l-4.94 5.389a1 1 0 0 1-.737.324H8.414a1 1 0 0 1 0-2h7.206L20 15.222V8.778L15.62 4H8.828L4 8.828v6.397zm9.293-.519a1 1 0 0 1 1.414-1.414l1 1a1 1 0 0 1-1.414 1.414l-1-1z" fill="#FFF" fill-rule="nonzero"/></svg>
          <h3>{{ textErr }}</h3>
          <button type="button" @click="loadTable()" class="btn btn-outline-danger mt-3">Повторить</button>
      </div>
    </div>
  </div>
  <div v-else style="width: 100%; height: 200px; text-align: center; padding-top: 100px;">
    <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "DataBaseReader",
  created() {
    this.loadTable();
  },
  data() {
    return {
      page: this.settings.page,
      loadingTable: false,
      errLoad: false,
      textErr: '',
      resp: {},
      selectAll: false,
      create: false,
      selectedFile: null
    };
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleFileChange(event, index, column) {
      this.resp.data.data[index].updated = true;
      this.selectedFile = event.target.files[0];
      const formData = new FormData()
      formData.append('image', this.selectedFile)
      formData.append('methods', 'admin/dataBase/updateImage')
      formData.append('column', column.columnName)
      formData.append('id',  this.resp.data.data[index].id)
      formData.append('table', this.settings.tableName)


      axios.post(this.$store.state.apiURL, formData)
      .then(response => {
        if(response.data.succ){
          this.resp.data.data[index][column.columnName] = response.data.data.newImage;
        }else{
          this.$swal.fire({
            title: 'Ошибка!',
            text: response.data.mess,
            icon: 'error',
            confirmButtonText: 'Закрыть'
          })
        }
      })
      .catch(() => {
        this.$swal.fire({
          title: 'Ошибка!',
          text: 'Не удалось загрузить изображение...',
          icon: 'error',
          confirmButtonText: 'Закрыть'
        })
      }); 
      this.resp.data.data[index].updated = false;
    },
    async createFunc(){
      this.create = true;
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/dataBase/create',
        table: this.settings.tableName,
        columns: JSON.stringify(this.settings.columns)
      });

      alert(JSON.stringify(sendData));

      if(sendData.succ){
        this.loadTable();
      }else{
        this.$swal.fire({
          title: 'Ошибка!',
          text: sendData.mess,
          icon: 'error',
          confirmButtonText: 'Закрыть'
        })
      }

      this.create = false;
    },
    async deleted(){
      var deletedID = [];
      this.resp.data.data.forEach(element => {
        if(element.checked){
          deletedID.push(element.id);
          element.updated = true;
        }
      });

      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/dataBase/delete',
        table: this.settings.tableName,
        id: JSON.stringify(deletedID),
      });

      if(sendData.succ){
        var newData = [];
        this.resp.data.data.forEach(element => {
          if(!element.checked){
            newData.push(element);
          }
        });
        this.resp.data.data = newData;
      }else{
        this.resp.data.data.forEach(element => {
          if(element.checked){
            element.updated = false;
          }
        });
      }
    },
    selectAllFunc(){
      this.resp.data.data.forEach(element => {
        if(this.selectAll){
          element.checked = true;
        }else{
          element.checked = false;
        }
      });
    },
    async updateVal(column, id, index){
      this.resp.data.data[index].updated = true;
      await this.$store.dispatch('postData', {
        methods: 'admin/dataBase/update',
        table: this.settings.tableName,
        column: column,
        id: id,
        val: this.resp.data.data[index][column]
      });

      this.resp.data.data[index].updated = false;
    },
    setCurrentPage(pages){
      this.page = pages;
      this.loadTable();
    },
    async btnEven(btn, data){
      if(btn.type == 'route'){
        var linkGet = btn.link.replaceAll(':id', data['id']);
        this.$router.push(linkGet);
      }
    },
    async loadTable(){
      sendData = this.settings;
      sendData.page = this.page;
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/dataBase/getData',
        settings: JSON.stringify(sendData),
      });

      this.resp = sendData;

      if(sendData.succ){
        this.errLoad = false;
        this.textErr = '';
      }else{
        this.errLoad = true;
        this.textErr = sendData.mess;
      }
      this.loadingTable = true;
      this.selectAll = false;
    }
  },
  computed:{
    displayedPages() {
      const maxDisplayedPages = this.settings.limit;
      const pages = [];
      let startPage = this.page;
      let endPage = this.resp.data.countRows;
      if (this.resp.data.countRows > maxDisplayedPages) {
        const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
        startPage = Math.max(this.page - halfDisplayedPages, 1);
        endPage = Math.min(this.page + halfDisplayedPages, this.resp.data.countRows);
        if (endPage - startPage < maxDisplayedPages) {
          if (this.page < halfDisplayedPages + 1) {
            endPage = maxDisplayedPages;
          } else {
            startPage = endPage - maxDisplayedPages + 1;
          }
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  }
};
</script>

<style lang="css" scoped>
  .changeInputIcon{
    width: 60px;
    position: absolute;
    height: 60px;
    opacity: 0.0;
  }
  .iconImage{
    height: 40px;
    text-align: center;
  }
  .containerEmptySpravki{
    width: 100%;
    text-align: center;
  }

  .page-link{
    background-color: transparent;
    border-color: red;
  }

  .emptyIcon{
    width: 100%;
    font-size: 160px;
    text-align: center;
  }
.form-control:disabled, .form-control:read-only{
  color: #6C7293;
  background-color: #000;
  background-clip: padding-box;
}
</style>