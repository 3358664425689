<template>
  <div class="container-fluid">
            <div class="row h-100 align-items-center justify-content-center" style="min-height: 100vh;">
                <div class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div class="bg-secondary rounded p-4 p-sm-5 my-4 mx-3">
                        <div class="row">
                          <div class="col-12 align-items-center">
                            <h3 style="text-align: center;">Авторизация</h3>
                          </div>
                          <div class="col-12" style="text-align: center;">
                            <img class="logoAuth" src="img/logo.svg">
                          </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input v-model="login" @keyup="checkPhone()" v-mask="'+7 (###) ###-##-##'" type="tel" class="form-control" :class="checkedPhone ? phoneValid ? 'is-valid': 'is-invalid' : ''" id="floatingInput" placeholder="+7 (777) 777 77 77">
                            <label for="floatingInput">Телефон</label>
                            <span v-if="loadPhoneCheck" class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true"></span>
                        </div>
                        <div class="form-floating mb-4">
                            <input v-model="password" type="password" class="form-control" id="floatingPassword" placeholder="*****">
                            <label for="floatingPassword">Пароль</label>
                        </div>
                        <button @click="loginFunc();" :disabled="loading" type="submit" class="btn btn-primary py-3 w-100 mb-4">Войти  <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
  name: "AuthPage",
  created() {},
  data() {
    return {
      login: '',
      password: '',
      loading: false,
      checkedPhone: false,
      phoneValid: false,
      loadPhoneCheck: false,
    };
  },
  props: {},
  methods: {
    async checkPhone(){
      if(this.login.length >= 18){
        this.loadPhoneCheck = true;
        this.checkedPhone = false;
        this.$store.dispatch('postData', {
          methods: 'admin/auth/check_phone',
          phone: this.login,
        }).then((result) => {
          if(result.succ){
            this.phoneValid = true;
          }else{
            this.phoneValid = false;
          }
          this.checkedPhone = true;
          this.loadPhoneCheck = false;
        }).catch((err) => {
          this.$swal.fire({
            title: 'Ошибка!',
            text: err.mess,
            icon: 'error',
            confirmButtonText: 'Cool'
          });
          this.loadPhoneCheck = false;
        });
      }
    },
    async loginFunc(){
      if(this.login.length >= 18){
        if(this.password.length >= 6){
          this.loading = true;
          var sendData = await this.$store.dispatch('postData', {
            methods: 'admin/auth/auth',
            phone: this.login,
            password: this.password,
          });
          if(sendData.succ){
            this.$store.state.adminInfo = sendData.data.userInfo;
            window.localStorage.setItem('phone', this.login);
            window.localStorage.setItem('password', this.password);
            this.$store.state.auth = true;
          }else{
            this.$swal.fire({
              title: 'Ошибка!',
              text: sendData.mess,
              icon: 'error',
              confirmButtonText: 'Cool'
            })
          }
          this.loading = false;
        }else{
          this.$swal.fire({
            title: 'Ошибка!',
            text: 'Вы указали слишком короткий пароль.',
            icon: 'error',
            confirmButtonText: 'Cool'
          })
        }
      }else{
        this.$swal.fire({
            title: 'Ошибка!',
            text: 'Телефон указан не коректно.',
            icon: 'error',
            confirmButtonText: 'Cool'
          })
      }
    }
  },
};
</script>

<style lang="css" scoped>
  .spinnerPhone{
    float: right;
    margin-top: -35px;
    margin-right: 10px;
    color: red;
  }
</style>