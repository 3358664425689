import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueTheMask from 'vue-the-mask'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios'
import VueAxios from 'vue-axios'


createApp(App)
.use(router)
.use(VueTheMask)
.use(VueSweetalert2)
.use(VueAxios, axios)
.use(store)
.mount('#app')
