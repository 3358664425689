<template>
  <div v-if="!loadPage" style="width: 100%; height: 200px; text-align: center; padding-top: 100px;">
    <div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else class="p-4">
    <div v-if="errorLoad" style="width: 100%; text-align: center; padding-top: 100px;">
        <svg width="180" height="180" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>error-1</title><path d="M12 10.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-6 6a1 1 0 1 1-1.414-1.414L10.586 12 8.293 9.707a1 1 0 0 1 1.414-1.414L12 10.586zm-8 4.64l1.77 2.136a1 1 0 0 1-1.54 1.276l-2-2.414a1 1 0 0 1-.23-.638V8.414a1 1 0 0 1 .293-.707l5.414-5.414A1 1 0 0 1 8.414 2h7.646a1 1 0 0 1 .737.324l4.94 5.39a1 1 0 0 1 .263.675v7.222a1 1 0 0 1-.263.676l-4.94 5.389a1 1 0 0 1-.737.324H8.414a1 1 0 0 1 0-2h7.206L20 15.222V8.778L15.62 4H8.828L4 8.828v6.397zm9.293-.519a1 1 0 0 1 1.414-1.414l1 1a1 1 0 0 1-1.414 1.414l-1-1z" fill="#FFF" fill-rule="nonzero"/></svg>
        <h3>{{ textError }}</h3>
        <button type="button" @click="loadPageFunc()" class="btn btn-outline-danger mt-3">Повторить</button>
    </div>
    <div v-else>
      <ul class="nav nav-pills">
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 8 ? 'active' : ''" @click="selctedTab =8;">Информация</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 7 ? 'active' : ''" @click="selctedTab =7;">Журнал</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 1 ? 'active' : ''" @click="selctedTab =1;">Досье</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 2 ? 'active' : ''" @click="selctedTab =2;">Документы</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 3 ? 'active' : ''" @click="selctedTab =3;">Участие в ЮР.лицах</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 4 ? 'active' : ''" @click="selctedTab =4;">Судебные задолжности</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 5 ? 'active' : ''" @click="selctedTab =5;">АДМ.Штрафы</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" :class="selctedTab == 6 ? 'active' : ''" @click="selctedTab =6;">ЕНПФ</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" @click="deleleteDosie()">Удалить</button>
        </li>
      </ul>
      <hr/>
      <div class="tabs-data" v-if="selctedTab == 1">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <h1>{{ dosie.dosie.fio }}:</h1>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>ИИН:</strong> {{ dosie.dosie.iin }}</li>
              <li class="list-group-item"><strong>Дата рождения:</strong> {{ dosie.dosie.berthday }}</li>
              <li class="list-group-item"><strong>Гражданство:</strong> {{ dosie.dosie.grag }}</li>
              <li class="list-group-item"><strong>Национальность:</strong> {{ dosie.dosie.nationaly }}</li>
              <li class="list-group-item"><strong>E-mail:</strong> {{ dosie.dosie.e_mail }}</li>
              <li class="list-group-item"><strong>Телефон:</strong> {{ dosie.dosie.iphome }}</li>
            </ul>
            <br><hr/>
            <div v-for="uch in dosie.dosie.uchet" :key="uch">
              <h4>{{ uch.title }}</h4>
              <ul class="list-group list-group-flush">
                <li v-for="textUch in uch.data" :key="textUch" class="list-group-item">{{ textUch }}</li>
              </ul>
            </div>
            <hr/>
            <h4>Медицинские данные:</h4>
            <ul v-for="med in dosie.dosie.med" :key="med" class="list-group list-group-flush">
                <li class="list-group-item"><strong>Учреждение: </strong> {{ med.med_name }}</li>
                <li class="list-group-item"><strong>Участковый врач: </strong> {{ med.uch_vrach }}</li>
                <li class="list-group-item"><strong>Номер участка: </strong> {{ med.num_uch }}</li>
                <li class="list-group-item"><strong>Дата прикрепления: </strong> {{ med.date_state }}</li>
            </ul>
            <hr/>
            <div v-for="receptes in dosie.dosie.receptes" :key="receptes">
              <h4>{{ receptes.title }}</h4>
              <ul v-for="lek in receptes.data" :key="lek" class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Заболевание: </strong> {{ lek.name }}</li>
                  <li class="list-group-item"><strong>Организация: </strong> {{ lek.org }}</li>
                  <li class="list-group-item"><strong>Препорат: </strong> {{ lek.preporat }}</li>
                  <li class="list-group-item"><strong>Дата получения: </strong> {{ lek.date_create }}</li>
              </ul>
            </div>
            <hr/>
            <h4>Автомобили: </h4>
            <div v-for="avto in dosie.dosie.avto" :key="avto">
              <hr>
                <h5>  --{{ avto.title }}</h5>
                <p>  --<strong>Год выпуска: </strong>{{ avto.yearth }}</p>
                <p>  --<strong>VIN код: </strong>{{ avto.vin }}</p>
                <p>  --<strong>Номер тех.паспорта: </strong>{{ avto.teh_pasport }}</p>
                <p>  --<strong>Номер кузова: </strong>{{ avto.kuzov_number }}</p>
                <p>  --<strong>Модель: </strong>{{ avto.model }}</p>
                <p>  --<strong>Цвет: </strong>{{ avto.color }}</p>
                <p>  --<strong>Объем двигателя: </strong>{{ avto.engin_weight }}</p>
              <hr>
            </div>

            <div v-for="ip in dosie.dosie.ip" :key="ip">
              <h2>ИП: {{ ip.title }}</h2>
              <ul v-for="ipinfo in ip.info" :key="ipinfo" class="list-group list-group-flush">
                  <li class="list-group-item">--{{ ipinfo }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-data" v-if="selctedTab == 2">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <div v-if="dosie.dosie.udv.title">
              <h1>{{ dosie.dosie.udv.title }}</h1>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Дата выдачи: </strong> {{ dosie.dosie.udv.timeline_start }}</li>
                  <li class="list-group-item"><strong>Дата окончания: </strong> {{ dosie.dosie.udv.timeline_end }}</li>
                  <li class="list-group-item"><strong>Срок действия: </strong> {{ dosie.dosie.udv.after_delay }}</li>
                  <li class="list-group-item"><strong>Кем выдан: </strong> {{ dosie.dosie.udv.organ }}</li>
              </ul>
            </div>
            <hr>
            <div v-if="dosie.dosie.pasport.title">
              <h1>{{ dosie.dosie.pasport.title }}</h1>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Дата выдачи: </strong> {{ dosie.dosie.pasport.timeline_start }}</li>
                  <li class="list-group-item"><strong>Дата окончания: </strong> {{ dosie.dosie.pasport.timeline_end }}</li>
                  <li class="list-group-item"><strong>Срок действия: </strong> {{ dosie.dosie.pasport.after_delay }}</li>
                  <li class="list-group-item"><strong>Кем выдан: </strong> {{ dosie.dosie.pasport.organ }}</li>
              </ul>
            </div>
            <hr>
            <div v-if="dosie.dosie.prava.title">
              <h1>Водительское удостоверение:</h1>
              <h4>{{ dosie.dosie.prava.title }}</h4>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Дата выдачи: </strong> {{ dosie.dosie.prava.timeline_start }}</li>
                  <li class="list-group-item"><strong>Дата окончания: </strong> {{ dosie.dosie.prava.timeline_end }}</li>
                  <li class="list-group-item"><strong>Срок действия: </strong> {{ dosie.dosie.prava.after_delay }}</li>
                  <li class="list-group-item"><strong>Кем выдан: </strong> {{ dosie.dosie.prava.organ }}</li>
              </ul>
            </div>
            <hr>
            <h2>Цифровые документы:</h2>
            <hr>
            <div class="row">
              <div @click="openImage(doc.link)" v-for="doc in dosie.docks"  :key="doc.id" class="col-md-3 containerDocks mb-5">
                <div class="imageDocks" :style="{backgroundImage: 'url('+doc.link+')'}"></div>
                <span>{{ doc.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-data" v-if="selctedTab == 3">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <div v-for="ur in dosie.dosie.ur" :key="ur">
              <h4>{{ ur.title }}</h4>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>БИН: </strong> {{ ur.bin }}</li>
                  <li class="list-group-item"><strong>Роль: </strong> {{ ur.role }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-data" v-if="selctedTab == 4">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <div v-for="sud in dosie.dosie.sud" :key="sud">
              <h3>{{ sud.title }}</h3>
              <ul class="list-group list-group-flush">
                  <li v-for="info in sud.info" :key="info" class="list-group-item">{{ info }}</li>
              </ul>
              <hr>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-data" v-if="selctedTab == 5">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <div v-for="shtrafes in dosie.dosie.shtrafes" :key="shtrafes">
              <h3>{{ shtrafes.title }}</h3>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><strong>Номер материал: </strong>{{ shtrafes.number_material }}</li>
                  <li class="list-group-item"><strong>Орган: </strong>{{ shtrafes.organ }}</li>
                  <li class="list-group-item"><strong>Сумма штрафа: </strong>{{ shtrafes.price }}</li>
                  <li class="list-group-item"><strong>Остаток долга: </strong>{{ shtrafes.after_price }}</li>
              </ul>
              <hr>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-data" v-if="selctedTab == 6">
        <div class="row">
          <div class="col-md-2">
            <div class="avatar" :style="{backgroundImage: 'url('+dosie.dosie.avatar+')'}"></div>
          </div>
          <div class="col-md-10">
            <div v-for="pensia in dosie.dosie.pensiya" :key="pensia">
              <h3>{{ pensia.title }}</h3>
              <ul class="list-group list-group-flush">
                  <li v-for="info in pensia.info" :key="info" class="list-group-item">{{ info }}</li>
              </ul>
              <hr>
            </div>
            <div v-for="pensia in dosie.dosie.last_pensiya" :key="pensia">
              <h3>{{ pensia.title }}</h3>
              <ul class="list-group list-group-flush">
                  <li v-for="info in pensia.info" :key="info" class="list-group-item">{{ info }}</li>
              </ul>
              <hr>
            </div>
          </div>
        </div>
      </div>


      <div class="tabs-data" v-if="selctedTab == 7">
        <div class="container-log" v-for="log in dosie.logs" :key="log.id">
          <span class="logMessage" :class="log.type == 'succ' ? 'success-log' : 'danger-log'">
            {{ log.text }}
          </span>
        </div>
      </div>


      <div class="tabs-data" v-if="selctedTab == 8">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" :class="dosie.info.status == 200 ? 'text-success': 'text-danger'">Статус: {{ dosie.info.status == 200 ? 'Успешно обработан' : 'Не выполнен' }}</li>
          <li class="list-group-item">ID: {{ dosie.info.id }}</li>
          <li class="list-group-item">Телефон: {{ dosie.info.phone }}</li>
          <li class="list-group-item">ИИН: {{ dosie.info.iin }}</li>
          <li class="list-group-item">Ссылка на биометрию: <a :href="dosie.info.biometrik_link">{{ dosie.info.biometrik_link }}</a></li>
          <li class="list-group-item">Дата начала: {{ dosie.info.date_create }}</li>
          <li class="list-group-item">Дата завершения: {{ dosie.info.date_off }}</li>
          <li class="list-group-item">Последнее сообщение: {{ dosie.info.text_status }}</li>
          <li class="list-group-item">Компания: <a href="#">{{ dosie.info.companyInfo.company_name }}</a></li>
          <li class="list-group-item">Сотрудник: <a href="#">{{ dosie.info.person_info.fio }}</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DosieInfoPage",
  created() {
    this.loadPageFunc();
  },
  data() {
    return {
      loadPage: false,
      errorLoad: false,
      textError: '',
      dosie: {},
      selctedTab: 8,
    };
  },
  props: {},
  methods: {
    async deleleteDosie(){
      this.loadPage = false;
      this.errorLoad = false;
      this.textError = '';
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/dosie/deleteDosie',
        id: this.$route.params.id,
      });

      if(sendData.succ){
        this.$router.back();
      }else{
        this.errorLoad = true;
        this.textError = sendData.mess;
        this.loadPage = true;
      }
    },
    async loadPageFunc(){
      this.loadPage = false;
      this.errorLoad = false;
      this.textError = '';
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/dosie/getDosieInfo',
        id: this.$route.params.id,
      });

      if(sendData.succ){
        this.errorLoad = false;
        this.textError = '';
        this.dosie = sendData.data;
      }else{
        this.errorLoad = true;
        this.textError = sendData.mess;
      }

      this.loadPage = true;
    }
  },
};
</script>

<style lang="css" scoped>
  .logMessage{
    max-width: 70%;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .container-log{
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
  .success-log{
    background-color: lawngreen;
    color: black;
  }
  .danger-log{
    background-color: red;
    color: black;
  }
  .tabs-data{
    padding-top: 30px;
  }
  .containerDocks{
    padding: 0px;
    text-align: center;
    cursor:zoom-in;
  }
  .containerDocks span{
    font-weight: bold;
  }
  .imageDocks{
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center center;
  }
  .list-group-item{
    padding-left: 2px;
    margin-left: 0px;
    background-color: transparent;
    color: white;
  }
  hr{
    color: white;
    background-color: white;
  }
    .avatar{
      width: 100%;
      padding-bottom: 100%;
      background-color: lightcyan;
      border-radius: 100%;
      border: dotted 3px limegreen;
      background-size: cover;
      background-position: center center;
    }
</style>