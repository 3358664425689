<template>
  <div class="container-fluid  pt-4 px-4">
    <div class="bg-secondary text-start rounded p-4">
      <div class="btn-group btn-group-sm">
        <button class="btn btn-info">Купить еще</button>
        <button class="btn btn-warning">Продлить</button>
      </div>
    </div>
  </div>
  <dataBase :settings="settings"></dataBase>
</template>

<script>
import dataBase from '@/components/data_base/Database_reader.vue'

export default {
  name: "ProxyPage",
  created() {},
  data() {
    return {
      settings: { 
        tableName: 'proxy',
        title: 'Редактирование прокси',
        deleted: true,
        created: true,
        limit: 20,
        defoultSort: {
          column: 'status',
          type: 'DESC'
        },
        pagination: true,
        page: 1,
        buttons: [
          {
            class: 'btn-outline-success',
            type: 'href',
            link: '#',
            title: 'Продлить'
          }
        ],
        columns: [
          {
            columnName: 'id',
            showName: 'ID',
            search: true,
            read: false,
            type: 'text',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'ip',
            showName: 'IP адресс',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'login',
            showName: 'Логин',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'password',
            showName: 'Пароль',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'count_send',
            showName: 'Кол-во запросов',
            search: true,
            read: false,
            type: 'number',
            typeSearch: '=',
            defVal: 0,
            searchVal: '',
          },
          {
            columnName: 'count_error',
            showName: 'Кол-во ошибок',
            search: true,
            read: false,
            type: 'number',
            defVal: 0,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'status',
            showName: 'Статус',
            search: true,
            read: true,
            type: 'select',
            defVal: '0',
            typeSearch: '=',
            searchVal: 'все',
            values: [
              {
                name: 'Активен',
                val: 1,
              },
              {
                name: 'Отключен',
                val: 0,
              },
              {
                name: 'Все',
                val: 'все',
              },
            ]
          },
          {
            columnName: 'error_text',
            showName: 'Последняя ошибка',
            search: true,
            read: false,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'last_get',
            showName: 'последний запрос',
            search: true,
            read: false,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'date_off',
            showName: 'Дата окончания',
            search: true,
            read: false,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
        ]
      }
    };
  },
  props: {},
  methods: {},
  components:{
    dataBase
  }
};
</script>

<style lang="css" scoped></style>