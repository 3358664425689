<template>
  <StatWidjet></StatWidjet>
  <div class="container-fluid pt-4 px-4">
    <div class="bg-secondary text-center rounded p-4">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h6 class="mb-0">Последние выпущенные ЭЦП:</h6>
      </div>
      <div class="table-responsive">
        <table class="table text-center align-middle table-bordered table-hover mb-0">
          <thead>
            <tr class="text-white">
              <th scope="col text-start"></th>
              <th scope="col">Статус</th>
              <th scope="col">Компания</th>
              <th scope="col">Сотрудник</th>
              <th scope="col">Пароль</th>
              <th scope="col">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="ecp in lastEsp" :key="ecp.id">
              <td class="text-start" :class="ecp.status == 200 ? 'text-success' : 'text-danger'">{{ this.formatTimed(ecp.date_create) }}</td>
              <td :class="ecp.status == 200 ? 'text-success' : 'text-danger'">{{ textStatus(ecp.status) }}</td>
              <td :class="ecp.status == 200 ? 'text-success' : 'text-danger'">{{ ecp.company_info.company_name }}</td>
              <td :class="ecp.status == 200 ? 'text-success' : 'text-danger'">{{ ecp.person_info.fio }}</td>
              <td :class="ecp.status == 200 ? 'text-success' : 'text-danger'">{{ ecp.password }}</td>
              <td>
                  <button type="button" @click="ecp.status == 200 ? openEcpInfo(ecp.id) : deleteEcp(ecp.id)" class="btn w-100 btn-sm" :class="ecp.status == 200 ? 'btn-success' : 'btn-danger'">
                    {{ ecp.status == 200 ? 'Подробнее' : 'Удалить' }}
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 px-4">
    <div class="bg-secondary text-center rounded p-4">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h6 class="mb-0">Последние запросы Досье:</h6>
      </div>
      <div class="table-responsive">
        <table class="table text-center align-middle table-bordered table-hover mb-0">
          <thead>
            <tr class="text-white">
              <th scope="col text-start"></th>
              <th scope="col">Статус</th>
              <th scope="col">Компания</th>
              <th scope="col">Сотрудник</th>
              <th scope="col">Пользователь</th>
              <th scope="col">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dosie in lastDosie" :key="dosie.id">
              <td class="text-start" :class="dosie.status == 200 ? 'text-success' : 'text-danger'">{{ formatTimed(dosie.date_create) }}</td>
              <td :class="dosie.status == 200 ? 'text-success' : 'text-danger'">{{ textStatus(dosie.status) }}</td>
              <td :class="dosie.status == 200 ? 'text-success' : 'text-danger'">{{ dosie.company_info.company_name }}</td>
              <td :class="dosie.status == 200 ? 'text-success' : 'text-danger'">{{ dosie.person_info.fio }}</td>
              <td :class="dosie.status == 200 ? 'text-success' : 'text-danger'">{{ dosie.userName }}</td>
              <td>
                  <button @click="dosie.status == 200 ? openDosieInfo(dosie.id) : deleteDosie(dosie.id)" type="button" class="btn w-100 btn-sm" :class="dosie.status == 200 ? 'btn-success' : 'btn-danger'">
                    {{ dosie.status == 200 ? 'Подробнее' : 'Удалить' }}
                  </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import StatWidjet from '@/components/widjets/Statististics.vue';
import moment from 'moment';
export default {
  name: "HomeView",
  data() {
    return {
      lastEsp: [],
      lastDosie: []
    };
  },
  created(){
    this.loadLastOperations();
  },
  methods: {
    openDosieInfo(id){
      this.$router.push('/dosieInfo/'+id);
    },
    openEcpInfo(id){
      this.$router.push('/ecpInfoPage/'+id);
    },
    deleteDosie(id){
      this.$store.dispatch('postData', {
          methods: 'admin/dosie/deleteDosie',
          id: id
      }).then((result) => {
        if(result.succ){
          this.loadLastOperations();
        }else{
          this.$swal.fire({
            title: 'Ошибка!',
            text: result.mess,
            icon: 'error',
            confirmButtonText: 'Cool'
          })
        }
      }).catch((err) => {
        this.$swal.fire({
          title: 'Ошибка!',
          text: err.mess,
          icon: 'error',
          confirmButtonText: 'Cool'
        })
      });
    },
    deleteEcp(id){
      this.$store.dispatch('postData', {
          methods: 'admin/ecp/deleteEcp',
          id: id
      }).then((result) => {
        if(result.succ){
          this.loadLastOperations();
        }else{
          this.$swal.fire({
            title: 'Ошибка!',
            text: result.mess,
            icon: 'error',
            confirmButtonText: 'Cool'
          })
        }
      }).catch((err) => {
        this.$swal.fire({
          title: 'Ошибка!',
          text: err.mess,
          icon: 'error',
          confirmButtonText: 'Cool'
        })
      });
    },
    textStatus(status){
      if(status == 500){
        return 'Ошибка ('+ status+')';
      }else if(status == 200){
        return 'Успешно';
      }else if(status == 222){
        return 'Ожидает биометрию';
      }else if(status == 111){
        return 'Ожидает ввода смс';
      }else{
        return 'В процессе';
      }
    },
    formatTimed(time) {
      const now = moment();
      const past = moment(time);
      const diffInMinutes = now.diff(past, 'minutes');
      const diffInHours = now.diff(past, 'hours');
      const diffInDays = now.diff(past, 'days');

      if (diffInMinutes < 1) {
        return 'только что';
      } else if (diffInMinutes < 60) {
        return `${this.declOfNum(diffInMinutes, ['минуту', 'минуты', 'минут'])} назад`;
      } else if (diffInHours < 24) {
        return `${this.declOfNum(diffInHours, ['час', 'часа', 'часов'])} назад`;
      } else {
        return `${this.declOfNum(diffInDays, ['день', 'дня', 'дней'])} назад`;
      }
    },
    declOfNum(number, titles) {
      const cases = [2, 0, 1, 1, 1, 2];
      return `${number} ${titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]}`;
    },
    async loadLastOperations(){
      this.$store.dispatch('postData', {
          methods: 'admin/last/last_operations',
      }).then((result) => {
        this.lastEsp = result.data.ecp;
        this.lastDosie = result.data.dosie;
      }).catch((err) => {
        this.$swal.fire({
          title: 'Ошибка!',
          text: err.mess,
          icon: 'error',
          confirmButtonText: 'Cool'
        })
      });
    }
  },
  components: {
    StatWidjet
  },
};
</script>


<style lang="css" scoped>
  .position-absolute2{
    width: 100%;
    position: relative;
    z-index: 9999999;
  }
</style>