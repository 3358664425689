<template>
  <div class="container-fluid pt-4 px-4">
    <div class="bg-secondary rounded-top p-4">
      <div class="row">
        <div class="col-12 col-sm-6 text-center text-sm-start">
            ©<a href="#">ТОО ReCASH</a>, Все права защищеный.
        </div>
        <div class="col-12 col-sm-6 text-center text-sm-end">
          Разработка программы <a href="https://appoffice.kz">AppOffice</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopirightVue",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css" scoped></style>