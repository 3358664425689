<template>
  <nav class="navbar navbar-expand bg-secondary navbar-dark sticky-top px-4 py-0">
    <a @click="this.$store.state.showMenu = !this.$store.state.showMenu;" class="d-lg-none sidebar-toggler flex-shrink-0">
      <i class="fa fa-bars"></i>
    </a>
    <div class="navbar-nav align-items-center ms-auto">
      <a v-if="getEcpState && getDosieState" href="#" class="nav-link">
        <i class="fa fa-check me-lg-2"></i>
        <span class="d-lg-inline-flex">Сервер <span class="text-success" style="margin-left: 5px;">(online)</span></span>
      </a>
      <a v-if="!getEcpState || !getDosieState" href="#" class="nav-link">
        <i v-if="!checkServer" class="fa fa-times me-lg-2 mr-3" ></i>
        <span v-else class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true" style="margin-right: 10px;"></span>
        <span class="d-lg-inline-flex">Сервер <span class="text-danger" style="margin-left: 5px;">(offline)</span></span>
      </a>
      <div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
            <img v-if="this.$store.state.adminInfo.avatar.length > 0" class="rounded-circle me-lg-2" :src="this.$store.state.adminInfo.avatar" alt="" style="width: 40px; height: 40px;">
            <img v-else class="rounded-circle me-lg-2" src="@/assets/no-ava.jpg" alt="" style="width: 40px; height: 40px;">
            <span class="d-none d-lg-inline-flex">{{ this.$store.state.adminInfo.name }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end bg-secondary border-0 rounded-0 rounded-bottom m-0">
            <a href="#" class="dropdown-item">Настройки</a>
            <a href="#" class="dropdown-item">Выйти</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  created() {
    this.checkServerTest();
  },
  data() {
    return {
      getEcpState: false,
      getDosieState: false,
      checkServer: true, 
    };
  },
  props: {},
  methods: {
    async checkServerTest(){
      await this.$store.dispatch('postData', {
          methods: 'egov/get_ecp',
          type: 'test',
      }).then((result) => {
          if(result.succ){
            this.getEcpState = true;
          }else{
            this.getEcpState = false;
          }
      }).catch(() => {
          this.getEcpState = false;
      });

      await this.$store.dispatch('postData', {
          methods: 'egov/get_dosie',
          type: 'test',
      }).then((result) => {
          if(result.succ){
            this.getDosieState = true;
          }else{
            this.getDosieState = false;
          }
      }).catch(() => {
          this.getDosieState = false;
      });

      this.checkServer = true;
    }
  },
};
</script>

<style lang="css" scoped></style>