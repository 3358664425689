<template>
  <div class="container-fluid pt-4 px-4" v-if="loadInfo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Операции</li>
        <li class="breadcrumb-item" aria-current="page">Выпуск ЭЦП</li>
        <li class="breadcrumb-item" aria-current="page">№{{ $route.params.id }}</li>
      </ol>
    </nav>
    <nav>
      <div class="nav nav-pills" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Информация</button>
        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Жупнал</button>
        <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Справки</button>
        <a :href="'https://appoffice.kz/ecp_get/api/index.php?type=downloadecp&id='+info.id" target="_blank" class="nav-link" id="nav-contact-tab" type="button">Скачать ЭЦП</a>
        <button @click="deletedEcp()" class="nav-link" type="button">Удалить</button>
      </div>
    </nav>
    <div class="tab-content mt-5" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Статус: {{ info.status }}</li>
          <li class="list-group-item">ID: {{ info.id }}</li>
          <li class="list-group-item">Телефон: {{ info.phone }}</li>
          <li class="list-group-item">ИИН: {{ info.iin }}</li>
          <li class="list-group-item">Ссылка на биометрию: <a :href="info.qr_code">{{ info.qr_code }}</a></li>
          <li class="list-group-item">СМС код: {{ info.sms_code }}</li>
          <li class="list-group-item">Дата начала: {{ info.date_create }}</li>
          <li class="list-group-item">Дата завершения: {{ info.date_off }}</li>
          <li class="list-group-item">Последнее сообщение: {{ info.last_message }}</li>
          <li class="list-group-item">Компания: <a href="#">{{ info.companyInfo.company_name }}</a></li>
          <li class="list-group-item">Сотрудник: <a href="#">{{ info.person_info.fio }}</a></li>
        </ul>
      </div>
      <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
        <div class="container-log" v-for="log in info.logs" :key="log.id">
          <span class="logMessage" :class="log.type == 'success' ? 'success-log' : 'danger-log'">
            {{ log.message }}
          </span>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
        <div class="containerEmptySpravki">
          <div class="emptyIcon"><i class="far fa-folder-open"></i></div>
          <h2>Нет полученных справок!</h2>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pt-4 px-4" v-else>
      <div class="bg-dark position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "EcpInfo",
  created() {
    this.loadInformation();
  },
  data() {
    return {
      loadInfo: false,
      errLoadInfo: false,
      textErrLoadInfo: '',
      info: {}
    };
  },
  props: {},
  methods: {
    async deletedEcp(){
      this.loadInfo = false;
      this.errLoadInfo = false;
      this.textErrLoadInfo = '';
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/ecp/deleteEcp',
        id: this.$route.params.id,
      });

      if(sendData.succ){
        this.$router.back();
      }else{
        this.errLoadInfo = true;
        this.textErrLoadInfo = sendData.mess;
      }
    },
    async loadInformation(){
      this.loadInfo = false;
      var sendData = await this.$store.dispatch('postData', {
        methods: 'admin/ecp/getInfo',
        id: this.$route.params.id,
      });
      if(sendData.succ){
        this.errLoadInfo = false;
        this.textErrLoadInfo = '';
        this.info = sendData.data.ecpInfo;
      }else{
        this.errLoadInfo = true;
        this.textErrLoadInfo = sendData.mess;
      }
      this.loadInfo = true;
    }
  },
};
</script>

<style lang="css" scoped>
  .list-group-item{
    background-color: transparent;
  }
  .containerEmptySpravki{
    width: 100%;
    text-align: center;
  }

  .emptyIcon{
    width: 100%;
    font-size: 160px;
    text-align: center;
  }
  .logMessage{
    max-width: 70%;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .container-log{
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
  .success-log{
    background-color: lawngreen;
    color: black;
  }
  .danger-log{
    background-color: red;
    color: black;
  }
</style>