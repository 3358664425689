<template>
      <Loader></Loader>
      <div v-if="$store.state.load" class="container-fluid position-relative d-flex p-0">
          <SideMenu v-if="$store.state.auth"></SideMenu>
          <div class="content" v-if="$store.state.auth">
              <NavBar></NavBar>

              <RouterView></RouterView>
              
              <Copiright></Copiright>
              
          </div>
          <TopButton v-if="$store.state.auth"></TopButton>
        <AuthPage  v-if="!$store.state.auth"></AuthPage>
    </div>
    <div v-else>
      <div class="bg-dark position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Загрузка...</span>
        </div>
      </div>
    </div>
</template>

<script>
import SideMenu from '@/components/navigation/SideMenu.vue';
import NavBar from '@/components/navigation/NavBar.vue';
import Loader from '@/components/active/LoaderVue.vue';
import TopButton from '@/components/active/TopButton.vue';
import Copiright from '@/components/footter/Copiright.vue';
import AuthPage from '@/views/AuthPage.vue';

export default {
  name: "App",
  components: {
    SideMenu,
    NavBar,
    Loader,
    TopButton,
    Copiright,
    AuthPage
  },
  created() {
    this.checkAuth();
  },
  data() {
    return {};
  },
  props: {},
  methods: {
    async checkAuth(){
      var phone = window.localStorage.getItem('phone');
      var password = window.localStorage.getItem('password');

      if(phone && password){
        var sendData = await this.$store.dispatch('postData', {
            methods: 'admin/auth/auth',
            phone: phone,
            password: password,
        });
        if(sendData.succ){
            this.$store.state.adminInfo = sendData.data.userInfo;
            this.$store.state.auth = true;
        }else{
            this.$store.state.auth = false;
        }
        this.$store.state.load = true;
      }else{
        this.$store.state.load = true;
        this.$store.state.auth = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>