<template>
  <dataBase :settings="settings"></dataBase>
</template>

<script>
import dataBase from '@/components/data_base/Database_reader.vue'

export default {
  name: "LangSettings",
  created() {},
  data() {
    return {
      settings: { 
        tableName: 'language',
        title: 'Редактирование языков',
        deleted: true,
        created: true,
        limit: 20,
        defoultSort: {
          column: 'id',
          type: 'DESC'
        },
        pagination: true,
        page: 1,
        buttons: [],
        columns: [
          {
            columnName: 'id',
            showName: 'ID',
            search: true,
            read: false,
            type: 'text',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
          },
          {
            columnName: 'val_name',
            showName: 'Значение',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'val_type',
            showName: 'Назначение',
            search: true,
            read: true,
            type: 'text',
            typeSearch: 'LIKE %%',
            defVal: '',
            searchVal: '',
          },
          {
            columnName: 'rus',
            showName: 'RUS',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'eng',
            showName: 'ENG',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'kaz',
            showName: 'KAZ',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
          {
            columnName: 'kim',
            showName: 'KIM',
            search: true,
            read: true,
            type: 'text',
            defVal: '',
            typeSearch: 'LIKE %%',
            searchVal: '',
          },
        ]
      }
    };
  },
  props: {},
  methods: {},
  components:{
    dataBase
  }
};
</script>

<style lang="scss" scoped></style>