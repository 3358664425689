<template>
  <div class="container-fluid  pt-4 px-4">
    <h1>Настройки:</h1>
    <div class="row">
      <div @click="this.$router.push('/proxy')" class="col-md-3">
        <div class="containerCardSettings">
          <i class="fas fa-link"></i><br>
          Прокси
        </div>
      </div>
      <div @click="this.$router.push('/spravki')" class="col-md-3">
        <div class="containerCardSettings">
          <i class="far fa-file"></i><br>
          Справки
        </div>
      </div>
      <div @click="this.$router.push('/server')" class="col-md-3">
        <div class="containerCardSettings">
          <i class="fas fa-server"></i><br>
          Сервер
        </div>
      </div>
      <div @click="this.$router.push('/langs')" class="col-md-3">
        <div class="containerCardSettings">
          <i class="fas fa-language"></i><br>
          Языки
        </div>
      </div>
      <div @click="this.$router.push('/credit_cards')" class="col-md-3">
        <div class="containerCardSettings">
          <i class="far fa-credit-card"></i><br>
          Платжные карты
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsVue",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="css" scoped>
  .containerCardSettings{
    color: black;
    text-align: center;
    margin: 10px;
    min-height: 200px;
    padding-top: 50px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .containerCardSettings i{
    font-size: 65px;
  }

  .containerCardSettings:nth-of-type(1){
    background-color: red !important;
  }

  .containerCardSettings:nth-of-type(2){
    background-color: greenyellow !important;
  }
</style>