<template>
  <div class="container-fluid pt-4 px-4">
    <div class="row g-4">
      <div class="col-sm-6 col-xl-3">
        <div class="bg-secondary rounded d-flex align-items-center justify-content-start p-4 ">
          <i class="fa fa-chart-line fa-3x text-primary"></i>
          <div class="ms-3">
            <p class="mb-2">Всего компаний</p>
            <h6 v-if="loadStat" class="mb-0">
              {{ countCompany }}
            </h6>
            <h6 v-else class="mb-0">
              <span class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true"></span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="bg-secondary rounded d-flex align-items-center justify-content-start p-4">
          <i class="fa fa-chart-bar fa-3x text-primary"></i>
          <div class="ms-3">
            <p class="mb-2">Всего Досье</p>
            <h6 v-if="loadStat" class="mb-0">{{ countDosie }}</h6>
            <h6 v-else class="mb-0">
              <span class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true"></span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="bg-secondary rounded d-flex align-items-center justify-content-start p-4">
          <i class="fa fa-chart-area fa-3x text-primary"></i>
          <div class="ms-3">
            <p class="mb-2">Всего ЭЦП</p>
            <h6 v-if="loadStat" class="mb-0">{{ countEcp }}</h6>
            <h6 v-else class="mb-0">
              <span class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true"></span>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-xl-3">
        <div class="bg-secondary rounded d-flex align-items-center justify-content-start p-4">
          <i class="fa fa-chart-pie fa-3x text-primary"></i>
          <div class="ms-3">
            <p class="mb-2">Всего ошибок</p>
            <h6 v-if="loadStat" class="mb-0">{{ countErr }}</h6>
            <h6 v-else class="mb-0">
              <span class="spinner-border spinner-border-sm spinnerPhone" role="status" aria-hidden="true"></span>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatisticsVue",
  created() {
    this.getStatistics();
  },
  data() {
    return {
      loadStat: false,
      countCompany: 0,
      countEcp: 0,
      countDosie: 0,
      countErr: 0,
    };
  },
  props: {},
  methods: {
    async getStatistics(){
      this.$store.dispatch('postData', {
          methods: 'admin/stat/get_stat',
      }).then((result) => {
        this.countCompany = result.data.countCompany;
        this.countEcp = result.data.countEcp;
        this.countDosie = result.data.countDosie;
        this.countErr = result.data.countErr;
        this.loadStat = true;
      }).catch((err) => {
        this.loadStat = true;
        this.$swal.fire({
            title: 'Ошибка!',
            text: err.mess,
            icon: 'error',
            confirmButtonText: 'Cool'
          });
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>