<template>
  <div class="container-fluid  pt-4 px-4">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
          Информация
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
          Сотрудники
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-permission-tab" data-bs-toggle="pill" data-bs-target="#pills-permission" type="button" role="tab" aria-controls="pills-permission" aria-selected="false">
          Разрешения
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
          Операции по ЭЦП
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false">
          Опереации по Досье
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link text-light" type="button">
          <i style="margin-right: 10px; color: white;" class="fas fa-trash"></i> Удалить
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active pt-5" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
        <div class="form-floating mb-3 px-3 mt-3">
          <div>Дата регистрации: </div>
        </div>
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="floatingInput" placeholder="Название компании">
          <label for="floatingInput">Название компании</label>
        </div>
        <div class="form-floating">
          <input type="tel" class="form-control" id="floatingPassword" placeholder="Телефон">
          <label for="floatingPassword">Телефон</label>
        </div>
        <div class="form-floating">
          <input type="email" class="form-control" id="floatingPassword" placeholder="E-mail">
          <label for="floatingPassword">E-mail</label>
        </div>
        <div class="form-floating">
          <textarea class="form-control" placeholder="Реквизиты компании" id="floatingTextarea2" style="height: 400px"></textarea>
          <label for="floatingTextarea2">Ревизиты</label>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
        <div class="bg-secondary text-center rounded p-4">
          <div class="table-responsive">
            <table class="table text-center align-middle table-bordered table-hover mb-0">
              <thead>
                <tr class="text-white">
                  <th scope="col text-start">ФИО</th>
                  <th scope="col">ИИН</th>
                  <th scope="col">Телефон</th>
                  <th scope="col">Статус</th>
                  <th scope="col">Дата регистрации</th>
                  <th scope="col">Последний визит</th>
                </tr>
              </thead>
              <tbody>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-permission" role="tabpanel" aria-labelledby="pills-permission-tab" tabindex="0">...</div>
      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">...</div>
      <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">...</div>
    </div>
  </div> 
</template>

<script>
export default {
  name: "CompanyInfo",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>