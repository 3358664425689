<template>
  <div class="container-fluid  pt-4 px-4">
    <div class="bg-secondary text-center rounded p-4">
      <h3 class="text-start">Параметры сервера:</h3>
      <div class="table-responsive">
        <table class="table text-center align-middle table-bordered table-hover mb-0">
          <thead>
            <tr class="text-white">
              <th></th>
              <th class="text-start">Тип сервера</th>
              <th class="text-start">Метод</th>
              <th>Статус</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td class="text-success">
                  <i class="fas fa-play-circle"></i>
                </td>
                <td class="text-start">Node JS</td>
                <td class="text-start">Получение ДОСЬЕ</td>
                <td>Активен</td>
                <td>
                  <button @click="reloadDosie()" class="w-100 btn btn-outline-success btn-sm">Перезагрузить</button>
                </td>
                <td>
                  <button class="w-100 btn btn-outline-danger btn-sm">Отключить</button>
                </td>
              </tr>
              <tr>
                <td class="text-success">
                  <i class="fas fa-play-circle"></i>
                </td>
                <td class="text-start">Node JS</td>
                <td class="text-start">Получение ЭЦП</td>
                <td>Активен</td>
                <td>
                  <button class="w-100 btn btn-outline-success btn-sm">Перезагрузить</button>
                </td>
                <td>
                  <button class="w-100 btn btn-outline-danger btn-sm">Отключить</button>
                </td>
              </tr>
              <tr>
                <td class="text-danger">
                  <i class="fas fa-pause-circle"></i>
                </td>
                <td class="text-start">Node JS</td>
                <td class="text-start">Получение справок</td>
                <td>Активен</td>
                <td>
                  <button class="w-100 btn btn-outline-success btn-sm">Перезагрузить</button>
                </td>
                <td>
                  <button class="w-100 btn btn-outline-warning btn-sm">Включить</button>
                </td>
              </tr>
              <tr>
                <td class="text-success">
                  <i class="fas fa-play-circle"></i>
                </td>
                <td class="text-start">Node JS</td>
                <td class="text-start">Сбор (Доски объявлений)</td>
                <td>Активен</td>
                <td>
                  <button class="w-100 btn btn-outline-success btn-sm">Перезагрузить</button>
                </td>
                <td>
                  <button class="w-100 btn btn-outline-danger btn-sm">Выключить</button>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="container-fluid  pt-4 px-4">
    <div class="bg-secondary text-start rounded p-4">
      <h3 class="text-start">Оплата сервера:</h3>
      <p class="text-start">Активен до: 09.05.2023 (14:33)</p>
      <p class="text-start">Стоимость: 175 270 KZT</p>
      <div class="btn-group">
        <button class="btn btn-outline-info">Продлить</button>
        <button class="btn btn-outline-success">Перезагрузить</button>
        <button class="btn btn-outline-danger">Отключить</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServerSettings",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>