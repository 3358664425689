<template>
  <div class="sidebar pe-4 pb-3" :style="this.$store.state.showMenu ? deviceWidth > 991 ? {marginLeft: '-250px'} : {marginLeft: '0px'} : {}">
    <nav class="navbar bg-secondary navbar-dark" >
        <a href="index.html"  class="navbar-brand mx-4 mb-3">
          <img class="logo" src="img/logo.svg"> 
        </a>
        <div class="d-flex align-items-center ms-4 mb-4">
          <div class="position-relative">
            <img v-if="this.$store.state.adminInfo.avatar.length > 0" class="rounded-circle" :src="$store.state.adminInfo.avatar" alt="" style="width: 40px; height: 40px;">
            <img v-else class="rounded-circle" src="@/assets/no-ava.jpg" alt="" style="width: 40px; height: 40px;">
          </div>
          <div class="ms-3">
            <h6 class="mb-0">{{ this.$store.state.adminInfo.name }}</h6>
            <span>{{ this.$store.state.adminInfo.type }}</span>
          </div>
        </div>
        <div class="navbar-nav w-100">
          <RouterLink class="nav-item nav-link" :class="$route.name == 'home' ? 'active': ''" to="/"><i class="fa fa-tachometer-alt me-2"></i>Главная</RouterLink>
          <RouterLink class="nav-item nav-link" :class="$route.name == 'companies' || $route.name == 'companyInfo'? 'active': ''" to="/companies"><i class="fa fa-th me-2"></i>Компании</RouterLink>
          <RouterLink class="nav-item nav-link" :class="$route.name == 'logs' || $route.name == 'ecpInfo' || $route.name == 'dosieInfo'? 'active': ''" to="/logs"><i class="fa fa-keyboard me-2"></i>Журналы</RouterLink>
          <RouterLink class="nav-item nav-link" :class="$route.name == 'settings' || $route.name == 'proxySettings' || $route.name == 'spravkiSettings'  || $route.name == 'serverSettings' || $route.name == 'langsSettings' || $route.name == 'CreditCards' ? 'active': ''" to="/settings"><i class="fa fa-table me-2"></i>Настройки</RouterLink>
          <RouterLink class="nav-item nav-link" :class="$route.name == 'stat' ? 'active': ''" to="/stat"><i class="fa fa-chart-bar me-2"></i>Статистика</RouterLink>
          <RouterLink class="nav-item nav-link" :class="$route.name == 'orders' ? 'active': ''" to="/orders"><i class="far fa-file-alt me-2"></i>Заказы</RouterLink>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  created() {},
  data() {
    return {
      deviceWidth: window.innerWidth,
    };
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>