<template>
  <div></div>
</template>

<script>
export default {
  name: "StatVue",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>