<template>
  <dataBase :settings="settings"></dataBase>
</template>

<script>
import dataBase from '@/components/data_base/Database_reader.vue'
export default {
  name: "CreditCards",
  created() {},
  components:{
    dataBase
  },
  data() {
    return {
      settings: { 
        tableName: 'credit_cards',
        deleted: true,
        created: true,
        limit: 20,
        defoultSort: {
          column: 'status',
          type: 'DESC'
        },
        pagination: true,
        page: 1,
        buttons: [],
        columns: [
          {
            columnName: 'id',
            showName: 'ID',
            search: true,
            read: false,
            type: 'number',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
            maskShow: false,
          },
          {
            columnName: 'cardNumber',
            showName: 'Номер карты',
            search: true,
            read: true,
            type: 'number',
            defVal: null,
            typeSearch: '=',
            searchVal: '',
            maskShow: true,
            mask: '#### #### #### ####'
          },
          {
            columnName: 'dateCard',
            showName: 'Дата окончания',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
            mask: '##/##',
            maskShow: true,
          },
          {
            columnName: 'nameCard',
            showName: 'Имя на карте',
            search: true,
            read: true,
            type: 'text',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
            maskShow: false,
            mask: ''
          },
          {
            columnName: 'cvvCard',
            showName: 'CVV Код',
            search: true,
            read: true,
            type: 'number',
            defVal: null,
            typeSearch: 'LIKE %%',
            searchVal: '',
            mask: '###',
            maskShow: true,
          },
          {
            columnName: 'status',
            showName: 'Статус',
            search: true,
            read: true,
            type: 'select',
            typeSearch: 'LIKE %%',
            defVal: '0',
            searchVal: 'все',
            maskShow: false,
            mask: '',
            values:[
              {
                name: 'Активная',
                val: 1,
              },
              {
                name: 'Отключена',
                val: 0,
              },
              {
                name: 'Все',
                val: 'все',
              }
            ]
          },
        ]
      }
    };
  },
  props: {},
  computed:{
    
  },  
  methods: {},
};
</script>

<style lang="scss" scoped></style>